/**
 * Styles for the Footer.js component
 */

footer {
  border-top: 1px solid $gray-300;
  font-size: 0.8em
}

.footer {
  padding-top: 5px;
  margin-bottom: 5px;
  color: #66615b;

//  position: absolute!important;
  bottom: 0;
  width: 100%;
//  height: 190px;

  height: 190px;
//  margin-top: -50px;

  &-link {
    display: block;
    color: #66615b;
  }

  &-header {
    text-transform: uppercase;
    color: #0080d0;
    font-weight: 700;
    font-size: 16px;
  }
}
