.home-body {
  margin-bottom: 35px;
  margin-top: 10px;

  & .home-description {
    font-size: 16px;
    color: #66615b;
    margin-bottom: 5px;
    line-height: 1.7em;
    font-weight: normal;
    text-transform: none;

  }

  & h2 {
    font-size: 24px;
    color: #2b2a28;
    margin-bottom: 10px;
    font-weight: 799;
    text-transform: uppercase;
  }

  & p {
    color: #66615b;
    margin-bottom: 5px;
    line-height: 1.7em;
  }
}

.carousel {
  width: 100%;
}

.our-services {
  width: 100%;
//  height: 200px;
  min-height: 230px;
  margin: 0;
  color: #FFF;
  text-align: center;

  &-header {
    color: #fff;
    text-transform: uppercase;
    font-size: 20px;
  //  padding-top: 40px;
    padding-top: 20%;
    font-weight: 700;
    padding-left: 15px;
    padding-right: 15px;
  }

  &-description {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
  }

  &-link {
    color: #fff;
    height: 100%;
    width: 100%;
    display:block;
  }

  &-1 {
    background-color: #0090d0;
  }

  &-2 {
    background-color: #0080d0;
  }

  &-3 {
    background-color: #0070c0;
  }

  &-4 {
    background-color: #0060c0;
  }
}
