.gallery {
  margin-top: 50px;
  min-height: 70vh;

  &-item {
    margin-bottom: 25px;

    &-image {
      width: 100%;
      height: auto;
      min-height: 200px;

      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }

  &-modal {
    &-item {

    }
    &-image {
      width: 100%;
      height: auto;
      min-height: 200px;

      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }

  &-upload {
    margin-bottom: 10px;
    margin-top: 5px;
    width: 100px;
  }
}
