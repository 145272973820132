@import "bootstrap";
@import "components/_footer";

@import "components/navbar";
@import "components/page";
@import "components/gallery";
@import "components/comments";
@import "components/contactUs";

@import "components/home";


@import "scss/paper-kit";

html,body
{
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

.root {
  position: relative;
  min-height: 100vh;
}

.location {
  width: 100%;
  height: 250px;
  max-height: 250px;
  min-height: 200px;

  &-title {
    font-size: 3.2em;
    margin: 0;
  }
}

.presentation-image {
  height: 500px;
  max-height: 500px;
  min-height: 500px;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.carousel {
  width: 100vw;
}

.carousel-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 750px;
  max-height: 65vh;
//  background-size: 100% 650px;
}

.carousel-caption {
  font-size: 20px;

  & p {
    font-size: 20px;
  }
}

/**
 * Form Overrides
 */
.form-control {
  &:focus {
    outline: none !important;
    box-shadow: none;
    border-color: $gray-400;
    border-bottom-color: $primary;
  }
}

.btn {
  i {
    font-size: 0.8rem;
  }
}


@keyframes fadeInOut {
  0%, 100% { opacity: 0.2 }
  50% { opacity: 0.7 }
}
