
.navbar {

  .mobile-navbar {
    position: absolute;
    width: 300px;
    right: 0px;
    top: 55px;
    background: #fff;

    &-closed {
      display: none!important;
    }
  }

  .logo {
    color: #0080d0!important;
  }

  & .navbar-dropdown {

    & .navbar-dropdown-toggle {
      background-color: transparent;
      color: #2b2a28;
      border: 0;
      padding-top: 10px;
      padding-bottom: 10px;

      &-noBorder {
        border: 0;
        color: #FFFFFF;
      }
    }

    &-menu {
      background-color: #555!important;
      margin-top: 13px!important;
    }

    &-item {
      color: #222!important;
      background-color: transparent;
      padding: 10px!important;
      min-width: 0;
    }

    &-link {
      background-color: transparent;
      line-height: 1.6;
      margin: 10px 3px;
      padding: 5px 10px;
      opacity: .8;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 700;
      color: #FFFFFF;
    }
  }

  & .navbar-link {
    background-color: transparent;
    color: #2b2a28!important;
  //  border: 2px solid #66615B;
    opacity: 1!important;
    font-weight: 700!important;



    &-noBorder {
      border: 0;
    }

    &-nav {
      float: right;
      margin-right: 35px;

      &-collapse {
        width: 100%;
      }
    }
  }

  & .mobile-navbar {

    &-nav {
      padding: 2px 25px;
    }

    &-link {
      color: #2b2a28!important;
    //  border: 2px solid #66615B;
      opacity: 1!important;
      font-weight: 700!important;
      line-height: 1!important;
      margin: 5px 3px!important;
      text-transform: uppercase;
      font-size: 14px!important;
      padding: 10px 15px;

      &-social {
        text-align: center;
      }

      &-sub {
        padding-left: 25px!important;
        font-size: 12px!important;
      }
    }
  }
}
