
.contact-us {
  margin-top: 50px;
  min-height: 70vh;

  &-send {
    width: 120px!important;
    height: 40px!important;
    margin-top: 10px;
    margin-left: 15px;
  }

  &-leave {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 15px;
    margin-top: 20px;
  }

  &-input {
    margin-right: 15px;

    &-comment {
      height: 100px!important;
    }

    &-group {
      margin-right: 15px;
    }
  }

  &-item {
    border-bottom: 1px solid #999;
    margin-bottom: 15px;

    &-name {
      font-weight: 800;
      font-size: 14px;
      padding-bottom: 5px;
    }

    &-date {
      font-weight: 500;
      font-size: 12px;
      padding-bottom: 5px;
      margin-left: 15px;
    }

    &-text {
      font-weight: 500;
      font-size: 12px;
    }
  }
}
